<template>
  <div id="index">
    <!-- 顶部导航 -->
    <NavTop ref="navTop" pageName="index"></NavTop>
    <!-- banner区域 -->
    <div class="banner">
      <el-carousel
          trigger="hover"
          height="100vh"
          arrow="never"
          :interval="5000"
      >
        <el-carousel-item v-for="item in bannerList" :key="item.id">
          <div class="banner-img">
            <img :src="item.url" alt=""/>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 内容区域 -->
    <div class="content">
      <!-- 热点素材 -->
      <div class="content-title" v-if="hotListData.length > 0">
        <div class="content-title-l">热点素材</div>
        <div class="content-title-r">
          <router-link
              :to="{
              path: '/home/search',
              query: { prop: 'hot_value' },
            }"
          >
            <p>查看更多</p>
          </router-link>
          <div class="icon">
            <img src="@/assets/images/icons/icon7.png" alt=""/>
          </div>
        </div>
      </div>
      <div class="content-area">
        <div
            class="content-box"
            v-for="item in hotListData"
            :key="item.materialInfoVO.materialId"
        >
          <div
              class="content-box-show"
              @mouseenter="item.materialInfoVO.show = true"
              @mouseleave="item.materialInfoVO.show = false"
              @click="
              jumpDetailsPage(
                item.materialInfoVO.materialName,
                item.materialInfoVO.materialId
              )
            "
          >
            <div class="show-image" v-show="!item.materialInfoVO.show">
              <img v-lazy="item.materialInfoVO.cover + '?x-oss-process=image/resize,p_20,limit_0,w_480'" alt=""/>
            </div>
            <div class="show-image" v-show="item.materialInfoVO.show">
              <img :src="getImageView(item.materialInfoVO.previewUrl) + '?x-oss-process=image/resize,p_20,limit_0,w_480'" alt=""/>
              <div class="buttons">
                <div
                    class="buttons-collection"
                    v-show="!item.materialInfoVO.isCollect || item.materialInfoVO.isCollect == 0"
                    @click.stop="openAddDialog(item.materialInfoVO)"
                >
                  收藏
                </div>
                <div
                    class="buttons-iscollection"
                    v-show="item.materialInfoVO.isCollect == 1"
                    @click.stop="onCancelCollection(item.materialInfoVO)"
                >
                  已收藏
                </div>
                <div
                    class="buttons-like"
                    @click.stop="
                    onLike(item.materialInfoVO.isLike, item.materialInfoVO)
                  "
                >
                  <img
                      :src="
                      !item.materialInfoVO.isLike ||
                      item.materialInfoVO.isLike == 0
                        ? require('@/assets/images/icons/icon5.png')
                        : require('@/assets/images/icons/icon6.png')
                    "
                      alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="content-box-info">
            <div class="info-title">{{ item.materialInfoVO.materialName }}</div>
            <!--            <div class="info-avatar">-->
            <!--              <img :src="item.authorVo.avatar" alt=""/>-->
            <!--            </div>-->
          </div>
        </div>
      </div>

      <!-- 最新素材 -->
      <div class="content-title" v-if="newListData.length > 0">
        <div class="content-title-l">最新素材</div>
        <div class="content-title-r">
          <router-link
              :to="{
              path: '/home/search',
              query: { prop: 'upload_time' },
            }"
          >
            <p>查看更多</p>
          </router-link>
          <div class="icon">
            <img src="@/assets/images/icons/icon7.png" alt=""/>
          </div>
        </div>
      </div>
      <div class="content-area">
        <div
            class="content-box"
            v-for="item in newListData"
            :key="item.materialInfoVO.materialId"
        >
          <div
              class="content-box-show"
              @mouseenter="item.materialInfoVO.show = true"
              @mouseleave="item.materialInfoVO.show = false"
              @click="
              jumpDetailsPage(
                item.materialInfoVO.materialName,
                item.materialInfoVO.materialId
              )
            "
          >
            <div class="show-image" v-show="!item.materialInfoVO.show">
              <img v-lazy="item.materialInfoVO.cover + '?x-oss-process=image/resize,p_20,limit_0,w_480'" alt=""/>
            </div>
            <div class="show-image" v-show="item.materialInfoVO.show">
              <img :src="getImageView(item.materialInfoVO.previewUrl) + '?x-oss-process=image/resize,p_20,limit_0,w_480'" alt=""/>
              <div class="buttons">
                <div
                    class="buttons-collection"
                    v-show="
                    !item.materialInfoVO.isCollect ||
                    item.materialInfoVO.isCollect == 0
                  "
                    @click.stop="openAddDialog(item.materialInfoVO)"
                >
                  收藏
                </div>
                <div
                    class="buttons-iscollection"
                    v-show="item.materialInfoVO.isCollect == 1"
                    @click.stop="onCancelCollection(item.materialInfoVO)"
                >
                  已收藏
                </div>
                <div
                    class="buttons-like"
                    @click.stop="
                    onLike(item.materialInfoVO.isLike, item.materialInfoVO)
                  "
                >
                  <img
                      :src="
                      !item.materialInfoVO.isLike ||
                      item.materialInfoVO.isLike == 0
                        ? require('@/assets/images/icons/icon5.png')
                        : require('@/assets/images/icons/icon6.png')
                    "
                      alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="content-box-info">
            <div class="info-title">{{ item.materialInfoVO.materialName }}</div>
            <!--            <div class="info-avatar">-->
            <!--              <img :src="item.authorVo.avatar" alt=""/>-->
            <!--            </div>-->
          </div>
        </div>
      </div>

      <!-- 推荐素材 -->
      <div class="content-title" v-if="recommendListData.length > 0">
        <div class="content-title-l">推荐素材</div>
        <div class="content-title-r">
          <router-link
              :to="{
              path: '/home/search',
              query: { prop: null },
            }"
          >
            <p>查看更多</p>
          </router-link>
          <div class="icon">
            <img src="@/assets/images/icons/icon7.png" alt=""/>
          </div>
        </div>
      </div>
      <div class="content-area">
        <div
            class="content-box"
            v-for="item in recommendListData"
            :key="item.materialInfoVO.materialId"
        >
          <div
              class="content-box-show"
              @mouseenter="item.materialInfoVO.show = true"
              @mouseleave="item.materialInfoVO.show = false"
              @click="
              jumpDetailsPage(
                item.materialInfoVO.materialName,
                item.materialInfoVO.materialId
              )
            "
          >
            <div class="show-image" v-show="!item.materialInfoVO.show">
              <img v-lazy="item.materialInfoVO.cover + '?x-oss-process=image/resize,p_20,limit_0,w_800'" alt=""/>
            </div>
            <div class="show-image" v-show="item.materialInfoVO.show">
              <img :src="getImageView(item.materialInfoVO.previewUrl) + '?x-oss-process=image/resize,p_20,limit_0,w_480'" alt=""/>
              <div class="buttons">
                <div
                    class="buttons-collection"
                    v-show="
                    !item.materialInfoVO.isCollect ||
                    item.materialInfoVO.isCollect == 0
                  "
                    @click.stop="openAddDialog(item.materialInfoVO)"
                >
                  收藏
                </div>
                <div
                    class="buttons-iscollection"
                    v-show="item.materialInfoVO.isCollect == 1"
                    @click.stop="onCancelCollection(item.materialInfoVO)"
                >
                  已收藏
                </div>
                <div
                    class="buttons-like"
                    @click.stop="
                    onLike(item.materialInfoVO.isLike, item.materialInfoVO)
                  "
                >
                  <img
                      :src="
                      !item.materialInfoVO.isLike ||
                      item.materialInfoVO.isLike == 0
                        ? require('@/assets/images/icons/icon5.png')
                        : require('@/assets/images/icons/icon6.png')
                    "
                      alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="content-box-info">
            <div class="info-title">{{ item.materialInfoVO.materialName }}</div>
            <!--            <div class="info-avatar">-->
            <!--              <img :src="item.authorVo.avatar" alt=""/>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
    </div>

    <!-- 添加收藏弹出框 -->
    <Favorites ref="favorites"></Favorites>
  </div>
</template>

<script>
import NavTop from "../../components/NavTop/NavTop.vue";
import Favorites from "@/components/Favorites/Favorites.vue";
// import { getImageView } from "@/utils/tools"
import homeApi from "@/api/home.js";
import userApi from "@/api/user.js";

export default {
  components: {
    NavTop,
    Favorites
  },
  data() {
    return {
      bannerList: [], //轮播图
      bannerHeight: 0,
      hotListData: [], //热点素材
      newListData: [], //最新素材
      recommendListData: [], //推荐素材
    };
  },
  created() {
    this.getBannerData(); //获取首页轮播
    this.getMaterialData(); //获取首页素材
  },
  mounted() {
    this.getURLParams(window.location.href); //获取URL携带的参数
  },
  destroyed() {
    // window.localStorage.removeItem('isTransfer');
  },
  methods: {
    // 获取URL携带的参数
    getURLParams(url) {
      let pattern = /(\w+)=(\w+)/ig;
      let parames = {};
      url.replace(pattern, ($, $1, $2) => {
        parames[$1] = $2;
      });
      if (parames.action === 'regist') {
        this.$refs.navTop.openRegisterDialog(true);
      }
      if (parames.action === 'resetPassword') {
        this.$refs.navTop.openRegisterDialog(false);
      }
    },
    // 跳转素材详情页
    jumpDetailsPage(name, material) {
      let routeData = this.$router.resolve({
        path: "/home/details",
        query: {
          name,
          material,
        },
      });
      window.open(routeData.href, "_blank");
    },
    // 获取首页轮播
    getBannerData() {
      homeApi.postBannerData({
        location: 1,
      }).then((res) => {
        this.bannerList = res.data.data;
      });
    },
    // 获取首页素材
    getMaterialData() {
      homeApi.postMaterialData({
        userId: this.$store.state.userId ? this.$store.state.userId : null
      }).then((res) => {
        this.hotListData = res.data.data.hotList;
        this.newListData = res.data.data.newList;
        this.recommendListData = res.data.data.recommendList;
      });
    },
    // 打开添加收藏弹框
    openAddDialog(item) {
      if (this.$store.state.userInfo) {
        this.$refs.favorites.staging = item;
        this.$refs.favorites.visible = true;
        this.$refs.favorites.collectionParams.materialId = item.materialId;
        this.$refs.favorites.getFavoritesData();
      } else {
        this.$refs.navTop.openLoginDialog();
      }
    },
    // 取消收藏
    onCancelCollection(item) {
      this.$refs.favorites.onCancelCollection(item);
    },
    // 点赞/取消点赞
    onLike(num, item) {
      if (this.$store.state.userInfo) {
        if (num === 0) {
          userApi
              .postAddLike({
                materialId: item.materialId,
                userId: this.$store.state.userId,
              })
              .then((res) => {
                if (res.data.code === 200) {
                  item.isLike = 1;
                } else {
                  this.$message.error(res.data.msg);
                }
              });
        } else {
          userApi
              .postCancelLike({
                materialId: item.materialId,
                userId: this.$store.state.userId,
              })
              .then((res) => {
                if (res.data.code === 200) {
                  item.isLike = 0;
                } else {
                  this.$message.error(res.data.msg);
                }
              });
        }
      } else {
        this.$refs.navTop.openLoginDialog();
      }
    },
  },
};
</script>

<style lang="less" scoped>
#index {
  width: 100%;
  min-height: 100vh;
  //background-color: #010301;
  padding-bottom: 60px;
  box-sizing: border-box;

  .banner {
    width: 100%;
    min-width: 1600px;
    margin: 0 auto;

    .el-carousel__item .banner-img {
      width: 100%;
      height: 100%;
      //min-width: 1600px;
      //min-height: 780px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .content {
    width: 1600px;
    margin: 0 auto;

    .content-title {
      width: 100%;
      padding: 62px 16px 32px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;

      .content-title-l {
        width: 283px;
        height: 70px;
        background: linear-gradient(to right,
        rgba(255, 255, 255, 1),
        rgba(157, 152, 253, 0.8));
        background-clip: text;
        color: transparent;
        display: inline-block;
        text-align: left;
        box-sizing: border-box;
        line-height: 70px;
        font-size: 36px;
        font-weight: 700;
      }

      .content-title-r {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;

        p {
          font-size: 16px;
          font-weight: 400;
          color: #b8b8b8;
        }

        .icon {
          width: 20px;
          height: 18px;
          display: flex;

          img {
            width: 100%;
            height: 100%;
            transform: translateY(2px);
          }
        }
      }
    }

    .content-area {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      .content-box {
        width: 376px;
        height: 211.5px;
        // height: 376px;
        // *23.5
        margin-right: 32px;
        margin-bottom: 32px;
        border-radius: 10px;
        cursor: pointer;
        position: relative;

        .content-box-show {
          width: 100%;
          height: 100%;

          .show-image {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            overflow: hidden;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              //width: 100%;
              height: 100%;
              object-fit: cover;
            }

            .buttons {
              width: 100%;
              padding: 10px 16px 0;
              box-sizing: border-box;
              display: flex;
              justify-content: space-between;
              align-items: center;
              position: absolute;
              top: 0;
              left: 0;

              .buttons-collection {
                width: 65px;
                height: 38px;
                background: #fff429;
                border-radius: 5px;
                text-align: center;
                line-height: 38px;
                font-size: 16px;
                color: #000000;
              }

              .buttons-iscollection {
                width: 65px;
                height: 38px;
                background: #343736;
                border-radius: 5px;
                text-align: center;
                line-height: 38px;
                font-size: 16px;
                color: #ffffff;
              }

              .buttons-like {
                width: 38px;
                height: 38px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }

        .content-box-info {
          width: 100%;
          padding: 0 16px 10px;
          box-sizing: border-box;
          border-radius: 0 0 10px 10px;
          background: linear-gradient(180deg,
          rgba(29, 33, 41, 0) 0,
          rgba(29, 33, 41, 0.8) 100%);
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: absolute;
          bottom: 0;
          left: 0;

          .info-title {
            width: calc(100% - 80px);
            font-size: 18px;
            font-weight: 400;
            color: #f9f9f9;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .info-avatar {
            width: 38px;
            height: 38px;
            border-radius: 50%;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
            }

            img[src=""],
            img:not([src]) {
              opacity: 0;
            }
          }
        }
      }
    }

    .content-area .content-box:nth-of-type(4n) {
      margin-right: 0;
    }

    .content-area .content-box:nth-of-type(n + 5) {
      margin-bottom: 0;
    }
  }

  // @media screen and (max-width: 1600px) {
  //   .content {
  //     width: 1280px;

  //     .content-area .content-box {
  //       width: 304px;
  //       height: 171px;
  //       // *19
  //       margin-right: 21.33px;
  //       margin-bottom: 21.33px;
  //     }
  //   }
  // }
}
</style>
