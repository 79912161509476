var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"index"}},[_c('NavTop',{ref:"navTop",attrs:{"pageName":"index"}}),_c('div',{staticClass:"banner"},[_c('el-carousel',{attrs:{"trigger":"hover","height":"100vh","arrow":"never","interval":5000}},_vm._l((_vm.bannerList),function(item){return _c('el-carousel-item',{key:item.id},[_c('div',{staticClass:"banner-img"},[_c('img',{attrs:{"src":item.url,"alt":""}})])])}),1)],1),_c('div',{staticClass:"content"},[(_vm.hotListData.length > 0)?_c('div',{staticClass:"content-title"},[_c('div',{staticClass:"content-title-l"},[_vm._v("热点素材")]),_c('div',{staticClass:"content-title-r"},[_c('router-link',{attrs:{"to":{
            path: '/home/search',
            query: { prop: 'hot_value' },
          }}},[_c('p',[_vm._v("查看更多")])]),_vm._m(0)],1)]):_vm._e(),_c('div',{staticClass:"content-area"},_vm._l((_vm.hotListData),function(item){return _c('div',{key:item.materialInfoVO.materialId,staticClass:"content-box"},[_c('div',{staticClass:"content-box-show",on:{"mouseenter":function($event){item.materialInfoVO.show = true},"mouseleave":function($event){item.materialInfoVO.show = false},"click":function($event){return _vm.jumpDetailsPage(
              item.materialInfoVO.materialName,
              item.materialInfoVO.materialId
            )}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!item.materialInfoVO.show),expression:"!item.materialInfoVO.show"}],staticClass:"show-image"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.materialInfoVO.cover + '?x-oss-process=image/resize,p_20,limit_0,w_480'),expression:"item.materialInfoVO.cover + '?x-oss-process=image/resize,p_20,limit_0,w_480'"}],attrs:{"alt":""}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.materialInfoVO.show),expression:"item.materialInfoVO.show"}],staticClass:"show-image"},[_c('img',{attrs:{"src":_vm.getImageView(item.materialInfoVO.previewUrl) + '?x-oss-process=image/resize,p_20,limit_0,w_480',"alt":""}}),_c('div',{staticClass:"buttons"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!item.materialInfoVO.isCollect || item.materialInfoVO.isCollect == 0),expression:"!item.materialInfoVO.isCollect || item.materialInfoVO.isCollect == 0"}],staticClass:"buttons-collection",on:{"click":function($event){$event.stopPropagation();return _vm.openAddDialog(item.materialInfoVO)}}},[_vm._v(" 收藏 ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.materialInfoVO.isCollect == 1),expression:"item.materialInfoVO.isCollect == 1"}],staticClass:"buttons-iscollection",on:{"click":function($event){$event.stopPropagation();return _vm.onCancelCollection(item.materialInfoVO)}}},[_vm._v(" 已收藏 ")]),_c('div',{staticClass:"buttons-like",on:{"click":function($event){$event.stopPropagation();return _vm.onLike(item.materialInfoVO.isLike, item.materialInfoVO)}}},[_c('img',{attrs:{"src":!item.materialInfoVO.isLike ||
                    item.materialInfoVO.isLike == 0
                      ? require('@/assets/images/icons/icon5.png')
                      : require('@/assets/images/icons/icon6.png'),"alt":""}})])])])]),_c('div',{staticClass:"content-box-info"},[_c('div',{staticClass:"info-title"},[_vm._v(_vm._s(item.materialInfoVO.materialName))])])])}),0),(_vm.newListData.length > 0)?_c('div',{staticClass:"content-title"},[_c('div',{staticClass:"content-title-l"},[_vm._v("最新素材")]),_c('div',{staticClass:"content-title-r"},[_c('router-link',{attrs:{"to":{
            path: '/home/search',
            query: { prop: 'upload_time' },
          }}},[_c('p',[_vm._v("查看更多")])]),_vm._m(1)],1)]):_vm._e(),_c('div',{staticClass:"content-area"},_vm._l((_vm.newListData),function(item){return _c('div',{key:item.materialInfoVO.materialId,staticClass:"content-box"},[_c('div',{staticClass:"content-box-show",on:{"mouseenter":function($event){item.materialInfoVO.show = true},"mouseleave":function($event){item.materialInfoVO.show = false},"click":function($event){return _vm.jumpDetailsPage(
              item.materialInfoVO.materialName,
              item.materialInfoVO.materialId
            )}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!item.materialInfoVO.show),expression:"!item.materialInfoVO.show"}],staticClass:"show-image"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.materialInfoVO.cover + '?x-oss-process=image/resize,p_20,limit_0,w_480'),expression:"item.materialInfoVO.cover + '?x-oss-process=image/resize,p_20,limit_0,w_480'"}],attrs:{"alt":""}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.materialInfoVO.show),expression:"item.materialInfoVO.show"}],staticClass:"show-image"},[_c('img',{attrs:{"src":_vm.getImageView(item.materialInfoVO.previewUrl) + '?x-oss-process=image/resize,p_20,limit_0,w_480',"alt":""}}),_c('div',{staticClass:"buttons"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                  !item.materialInfoVO.isCollect ||
                  item.materialInfoVO.isCollect == 0
                ),expression:"\n                  !item.materialInfoVO.isCollect ||\n                  item.materialInfoVO.isCollect == 0\n                "}],staticClass:"buttons-collection",on:{"click":function($event){$event.stopPropagation();return _vm.openAddDialog(item.materialInfoVO)}}},[_vm._v(" 收藏 ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.materialInfoVO.isCollect == 1),expression:"item.materialInfoVO.isCollect == 1"}],staticClass:"buttons-iscollection",on:{"click":function($event){$event.stopPropagation();return _vm.onCancelCollection(item.materialInfoVO)}}},[_vm._v(" 已收藏 ")]),_c('div',{staticClass:"buttons-like",on:{"click":function($event){$event.stopPropagation();return _vm.onLike(item.materialInfoVO.isLike, item.materialInfoVO)}}},[_c('img',{attrs:{"src":!item.materialInfoVO.isLike ||
                    item.materialInfoVO.isLike == 0
                      ? require('@/assets/images/icons/icon5.png')
                      : require('@/assets/images/icons/icon6.png'),"alt":""}})])])])]),_c('div',{staticClass:"content-box-info"},[_c('div',{staticClass:"info-title"},[_vm._v(_vm._s(item.materialInfoVO.materialName))])])])}),0),(_vm.recommendListData.length > 0)?_c('div',{staticClass:"content-title"},[_c('div',{staticClass:"content-title-l"},[_vm._v("推荐素材")]),_c('div',{staticClass:"content-title-r"},[_c('router-link',{attrs:{"to":{
            path: '/home/search',
            query: { prop: null },
          }}},[_c('p',[_vm._v("查看更多")])]),_vm._m(2)],1)]):_vm._e(),_c('div',{staticClass:"content-area"},_vm._l((_vm.recommendListData),function(item){return _c('div',{key:item.materialInfoVO.materialId,staticClass:"content-box"},[_c('div',{staticClass:"content-box-show",on:{"mouseenter":function($event){item.materialInfoVO.show = true},"mouseleave":function($event){item.materialInfoVO.show = false},"click":function($event){return _vm.jumpDetailsPage(
              item.materialInfoVO.materialName,
              item.materialInfoVO.materialId
            )}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!item.materialInfoVO.show),expression:"!item.materialInfoVO.show"}],staticClass:"show-image"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.materialInfoVO.cover + '?x-oss-process=image/resize,p_20,limit_0,w_800'),expression:"item.materialInfoVO.cover + '?x-oss-process=image/resize,p_20,limit_0,w_800'"}],attrs:{"alt":""}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.materialInfoVO.show),expression:"item.materialInfoVO.show"}],staticClass:"show-image"},[_c('img',{attrs:{"src":_vm.getImageView(item.materialInfoVO.previewUrl) + '?x-oss-process=image/resize,p_20,limit_0,w_480',"alt":""}}),_c('div',{staticClass:"buttons"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                  !item.materialInfoVO.isCollect ||
                  item.materialInfoVO.isCollect == 0
                ),expression:"\n                  !item.materialInfoVO.isCollect ||\n                  item.materialInfoVO.isCollect == 0\n                "}],staticClass:"buttons-collection",on:{"click":function($event){$event.stopPropagation();return _vm.openAddDialog(item.materialInfoVO)}}},[_vm._v(" 收藏 ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.materialInfoVO.isCollect == 1),expression:"item.materialInfoVO.isCollect == 1"}],staticClass:"buttons-iscollection",on:{"click":function($event){$event.stopPropagation();return _vm.onCancelCollection(item.materialInfoVO)}}},[_vm._v(" 已收藏 ")]),_c('div',{staticClass:"buttons-like",on:{"click":function($event){$event.stopPropagation();return _vm.onLike(item.materialInfoVO.isLike, item.materialInfoVO)}}},[_c('img',{attrs:{"src":!item.materialInfoVO.isLike ||
                    item.materialInfoVO.isLike == 0
                      ? require('@/assets/images/icons/icon5.png')
                      : require('@/assets/images/icons/icon6.png'),"alt":""}})])])])]),_c('div',{staticClass:"content-box-info"},[_c('div',{staticClass:"info-title"},[_vm._v(_vm._s(item.materialInfoVO.materialName))])])])}),0)]),_c('Favorites',{ref:"favorites"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/icons/icon7.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/icons/icon7.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/icons/icon7.png"),"alt":""}})])
}]

export { render, staticRenderFns }